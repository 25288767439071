import React from "react"

const Short = () => (
  <section>
    <h4>Du Maurier Limited In Short</h4>
    <h5>2001-2003 System Development Engineer, Du Maurier Limited</h5>
    <p>
      Innovative J2EE for retailers developed in a small, distributed team.
    </p>
    <p>
      We developed a novel, internet enabled point of sale system, with accompanying management intranet.
      Java and J2EE feels much more comfortable to me than Visual Basic. This was a fine opportunity to switch
      the language used in my day job.
    </p>
    <p>
      Distribution is cool but the physicality of a co-located agile team is special.
    </p>
    <p>
      Experiences
      - Java
      - Swing
      - J2EE (EJB, JMS, JSP and Servlets) on JBoss
      - HTML (some JavaScript)
      - XML and XSLT
      - JDBC and SQL to MySQL RDBMS
      - Mandrake Linux
      - Ant
      - CVS
      - OpenLDAP
      - Apache HTTPD
      - Distributed development
      - Design Patterns
    </p>
    <p>A small family firm based in Leicester providing retail management systems and services. I
      worked from home as part of a small team developing a innovative retail management system
      using web based technology coded in Java.</p>
    <ul>
      <li>Helped to design and develop all areas of the system. The majority of the team started
        with limited Java skills so I helped to mentor and train these developers.
      </li>
      <li>Routine retail operations were performed through a modular and flexible Swing GUI.</li>
      <li>Head office, managerial and administrative functions were performed through a web
        interface using HTML (with a little JavaScript). This presentation layer mixed servlets
        and JSPs, XML and XSLT.
      </li>
      <li>Initially, relational database (MySQL) access was by direct SQL through JDBC. This
        was quickly replaced by JBoss JEE middleware. EJB entity beans and some LDAP
        were used for data access. Asynchronous processing was driven by JMS.
      </li>
      <li>Installed and maintained the development server (Mandrake Linux) including version
        control using CVS over SSH.
      </li>
      <li>Responsible for the Ant build and the deployment scripts.</li>
      <li>Responsible for developing the production configuration and server installation on
        Mandrake Linux.
      </li>
    </ul>
  </section>
)

export default Short
