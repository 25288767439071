import React from "react"
import Experiences from "../experiences"

const Full = () => (
  <section>
    <h4>Full Details</h4>
    <h5>LinkedIn</h5>
    <h6>System Development Engineer
      Du Maurier Limited

      2001 – 2003 2 years

      Leicester, United Kingdom</h6>

    <p>Innovative J2EE for retailers developed in a small, distributed team.</p>
    <p>
      We developed a novel, internet enabled point of sale system, with accompanying management intranet. Java and J2EE
      feels much more comfortable to me than Visual Basic. This was a fine opportunity to switch the language used in my
      day job.
    </p>
    <p>Distribution is cool but the physicality of a co-located agile team is special.</p>

    <Experiences bullets={[
      { point: "Java" },
      { point: "Swing" },
      { point: "J2EE (EJB, JMS, JSP and Servlets) on JBoss" },
      { point: "HTML (some JavaScript)" },
      { point: "XML and XSLT" },
      { point: "JDBC and SQL to MySQL RDBMS" },
      { point: "Mandrake Linux" },
      { point: "Ant" },
      { point: "CVS" },
      { point: "OpenLDAP" },
      { point: "Apache HTTPD" },
      { point: "Distributed development" },
      { point: "Design Patterns" },
    ]} title='Experiences'/>

    <h4>[SHORT}</h4>
    <h5>2001-2003 Du Maurier Limited</h5>

    <p>A small family firm based in Leicester providing retail management systems and services. I worked from home as
      part of a small team developing a innovative retail management system using web based technology. It was coded in
      Java.</p>

    <h4>[LONG]</h4>
    <h5>
      System Development Engineer

      2001 - 2003 Du Maurier Limited
    </h5>

    <Experiences bullets={[

      { point: "The routine retail operations were performed through a Java Swing GUI. This had to be modular, configurable and highly flexible to cope with the variety of devices on which it might be run." },

      { point: "Head office, managerial and administrative functions were performed through a web-based interface using HTML (with a little JavaScript). This presentation layer was coded in Java using servlets and JSPs. Where appropriate, XML and XSLT were used." },

      { point: "Initially, relational database access was by direct SQL through JDBC. This was replaced by J2EE-based middleware." },

      { point: "EJBs were used for primary data persistence with LDAP for rarely changing information. JMS and MDBs were used for asynchronous processing." },

      { point: "Installed and maintained a development server (on Mandrake Linux).  This ran JBoss, MySQL and OpenLDAP." },

      { point: "Version control used CVS running over SSH. Responsible for the initial CVS configuration." },

      { point: "Responsible for creating and developing the Ant build and deployment scripts." },

      { point: "Responsible for developing the production configuration and server installation." },
    ]}/>


    <h4>[SHORT]</h4>

    <Experiences bullets={[

      { point: "Helped to design and develop all areas of the system. The majority of the team started with limited Java skills so I helped to mentor and train these developers." },
      { point: "Routine retail operations were performed through a modular and flexible Swing GUI." },
      { point: "Head office, managerial and administrative functions were performed through a web interface using HTML (with a little JavaScript). This presentation layer mixed servlets and JSPs, XML and XSLT." },
      { point: "Initially, relational database (MySQL) access was by direct SQL through JDBC. This was quickly replaced by JBoss JEE middleware. EJB entity beans and some LDAP were used for data access.  Asynchronous processing was driven by JMS." },
      { point: "Installed and maintained the development server (Mandrake Linux) including version control using CVS over SSH." },
      { point: "Responsible for the Ant build and the deployment scripts." },
      { point: "Responsible for developing the production configuration and server installation on Mandrake Linux." },
    ]}/>


  </section>
)

export default Full
