import React from "react"
import Short from "./dumaurier/short"
import Full from "./dumaurier/full"

const DuMaurier = () => (
  <section>
    <Short/>
    <hr/>
    <Full/>
  </section>
)

export default DuMaurier
