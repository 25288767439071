import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import DuMaurier from "../../components/dumaurier"

const DuMaurierPage = () => (
  <Layout>
    <SEO title="Résumé" keywords={[`cv`, `resume`, `life`, `personal`, `robertburrelldonkin`]}/>
    <DuMaurier/>
  </Layout>
)

export default DuMaurierPage
